window.SearchBar = (searchQuery) => {
    return {
        search: searchQuery,
        init() {
            this.$watch('search', (value) => {
                if (value.trim() !== '') {
                    const url = new URL(window.location);
                    url.searchParams.set('s', value);
                    url.searchParams.delete('page');
                    window.history.replaceState({}, '', url);
                }
            });
        },

        jsonStringify: (obj) => {
            return JSON.stringify(obj);
        },
    };
};
